import "../css/style.css";
import Message from "./Message";
import { useState, useEffect, useRef } from "react";
import { v4 as uuidv4 } from "uuid";

function Messenger({ showPopup, setLink }) {
  const [newMessage, setNewMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [textareaHeight, setTextareaHeight] = useState();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const domain = process.env.REACT_APP_DOMAIN;
  const textareaRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const textareaElement = textareaRef.current;

    const handleFocus = () => document.body.classList.add("keyboard-open");
    const handleBlur = () => document.body.classList.remove("keyboard-open");

    if (textareaElement) {
      textareaElement.addEventListener("focus", handleFocus);
      textareaElement.addEventListener("blur", handleBlur);
    }

    return () => {
      if (textareaElement) {
        textareaElement.removeEventListener("focus", handleFocus);
        textareaElement.removeEventListener("blur", handleBlur);
      }
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      const latestMessage = document.querySelector(".chatBoxTop > :last-child");
      if (latestMessage) {
        latestMessage.scrollIntoView({ behavior: "smooth" });
      }
    }, 0);
  }, [messages]);

  function showIfImage(newMessage) {
    const links = newMessage.match(/\b(http|https)(:\/\/)(\S+)\b/gi);
    if (links != null) {
      const link = links[0];
      if (
        !link.includes(".mp4") &&
        !link.includes(".avi") &&
        !link.includes(".mov")
      ) {
        setLink(link);
        showPopup(true);
      }
    }
  }

  const adjustTextareaHeight = (event) => {
    const element = event.target;
    element.style.height = "auto";
    const newHeight = Math.max(
      element.scrollHeight,
      screenWidth < 786 ? 40 : 63
    );
    element.style.height = `${newHeight}px`;
    setTextareaHeight(`${newHeight}px`);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!newMessage.trim()) return;

    const id = uuidv4();
    const reply = { id: uuidv4(), text: "", own: false };

    setMessages([...messages, { id, text: newMessage, own: true }, reply]);

    if (screenWidth < 786) {
      setTextareaHeight("40px");
    } else {
      setTextareaHeight("63px");
    }

    try {
      setNewMessage("");

      const response = await fetch(`${domain}/answer`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify({ question: newMessage }),
        headers: { "Content-type": "application/json; charset=UTF-8" },
      });
      const data = await response.json();

      setMessages((prevMessages) =>
        prevMessages.map((message) =>
          message.id === reply.id ? { ...message, text: data.answer } : message
        )
      );

      showIfImage(data.answer);
    } catch (error) {
      console.error(error);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSubmit(event);
    }
  };

  return (
    <div className="min-h-screen flex flex-col">
      <div className="header w-full justify-between items-center">
        <img
          className="maxxLogo w-20 md:w-28 lg:w-32 xl:w-32 2xl:w-1/12"
          src={require("../images/maxxLogo.jpg")}
          alt="logo-img"
        />
        <h1 className="center text-3xl sm:text-3xl md:text-4xl lg:text-5xl 2xl:text-7xl">
          Ask Maxx
        </h1>
      </div>
      <div className="flex-1 messenger">
        <div className="chatMenu">
          <div className="chatMenuWrapper"></div>
        </div>

        <div className="chatBox">
          <div className="chatBoxWrapper">
            <div className="chatBoxTop">
              {messages.map((message) => (
                <Message
                  text={message.text}
                  own={message.own}
                  key={message.id}
                  showIfImage={showIfImage}
                />
              ))}
            </div>
            <div className="chatBoxBottom"></div>
          </div>
        </div>

        <div className="chatOnline">
          <div className="chatOnlineWrapper"></div>
        </div>
      </div>

      <form className="formStyle" onSubmit={handleSubmit}>
        <div className="chatBox1">
          <textarea
            ref={textareaRef}
            type="text"
            className="messageInput"
            placeholder="Enter your query"
            onChange={(e) => {
              setNewMessage(e.target.value);
              adjustTextareaHeight(e);
            }}
            onKeyDown={handleKeyDown}
            maxLength={200}
            value={newMessage}
            style={{
              height: textareaHeight || (screenWidth < 786 ? "40px" : "63px"),
              maxHeight: "150px",
              overflowY: "auto",
            }}
          ></textarea>
          <button id="chat-icon" type="submit" className="ml-2">
            <i className="fa-solid fa-circle-arrow-up fa-xl"></i>
          </button>
        </div>
      </form>
    </div>
  );
}

export default Messenger;
