const ImagePopup = ({ isOpen, link, setLink, setIsPopupOpen }) => {
  const togglePopup = () => {
    setLink("");
    setIsPopupOpen(false);
  };

  return (
    <>
      {/* Popup container */}
      {isOpen && (
        <div
          className="fixed inset-0 z-50 overflow-auto bg-gray-500 bg-opacity-50 flex justify-center items-center"
          onClick={togglePopup}
        >
          {/* Popup content */}
          <div className="bg-slate-300 rounded-lg overflow-hidden w-4/5 md:w-2/3 lg:w-1/2 w-70vw h-70vh">
            {/* Popup header */}
            <div className="flex justify-end p-4">
              <button onClick={togglePopup}>
                <svg
                  className="w-6 h-6 text-gray-600 hover:text-gray-800"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>

            {/* Popup body */}
            <div className="p-4 flex justify-center items-center">
              {
                <img
                  src={link}
                  alt="Popup Chart"
                  className="w-full h-auto max-w-full max-h-full mx-auto"
                />
              }
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ImagePopup;
