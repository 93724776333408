import { useEffect, useState } from "react";
import showdown from "showdown";

function removeNonTableTags(html) {
  const allowedTags = [
    "table",
    "tr",
    "th",
    "td",
    "a",
    "li",
    "ul",
    "ol",
    "b",
    "i",
    "strong",
    "em",
    "s",
  ];

  const pattern = new RegExp(
    `<(?!\/?(${allowedTags.join("|")})\\b)[^>]*>`,
    "gi"
  );

  return html.replace(pattern, "");
}

function Message({ own, text, showIfImage }) {
  const [loading, setLoading] = useState(true);

  const handleCLick = (e) => {
    e.preventDefault();
    showIfImage(text);
  };

  useEffect(() => {
    if (text) {
      setLoading(false);
    }
  }, [text]);

  const converter = new showdown.Converter();
  converter.setOption("tables", true);
  let markdownContent = text;
  markdownContent = converter.makeHtml(markdownContent);
  markdownContent = removeNonTableTags(markdownContent);

  const renderVideo = (url) => {
    return (
      <video controls>
        <source src={url} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    );
  };

  const renderContent = () => {
    if (
      (text.startsWith("http") || text.startsWith("https")) &&
      (text.endsWith(".mp4") || text.endsWith(".avi") || text.endsWith(".mov"))
    ) {
      return renderVideo(text);
    } else {
      return (
        <p
          className="messageText"
          onClick={(e) => handleCLick(e)}
          dangerouslySetInnerHTML={{ __html: markdownContent }}
        ></p>
      );
    }
  };

  return (
    <div className={own ? "message own" : "message"}>
      <div className="messageTop">
        {loading ? (
          <div className="loading dot-flashing"></div>
        ) : (
          renderContent()
        )}
      </div>
      <div className="messageBottom"></div>
    </div>
  );
}

export default Message;
