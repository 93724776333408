import React, { useState } from "react";
import {
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBInput,
  MDBCardImage,
} from "mdb-react-ui-kit";
import "../css/login.css";
import "mdb-react-ui-kit/dist/css/mdb.min.css";

function LoginScreen({ onLogin }) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const handleLogin = () => {
    if (username === "maxx" && password === "maxx") {
      onLogin();
    } else {
      setUsername("");
      setPassword("");
      setError("Username or password entered is incorrect!");
    }
  };

  const handleForget = () => {
    setUsername("maxx");
    setPassword("maxx");
  };

  return (
    <MDBContainer fluid>
      <MDBRow className="d-flex justify-content-center align-items-center h-100">
        <MDBCol col="12">
          <MDBCard
            className="bg-white my-5 mx-auto"
            style={{ borderRadius: "1rem", maxWidth: "500px" }}
          >
            <MDBCardBody className="p-5 w-100 d-flex flex-column">
              <div className="mx_center text-center">
                <div>
                  <MDBCardImage
                    src="img/maxx_.png"
                    alt="login form"
                    className="rounded-start w-100 text-center"
                  />
                  {/* <span className="text-center">Ask Maxx</span> */}
                </div>
              </div>
              <h2 className="fw-bold mb-2 text-center">Sign in</h2>

              <MDBInput
                onChange={(e) => setUsername(e.target.value)}
                value={username}
                wrapperClass="mb-4 w-100"
                label="Username"
                id="formControlLg"
                type="email"
                size="lg"
              />

              <MDBInput
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                wrapperClass="mb-4 w-100"
                label="Password"
                id="formControlLg2"
                type="password"
                size="lg"
              />

              <span
                size="lg"
                onClick={handleForget}
                className="text-center forget mb-3"
              >
                Forgot Password ?
              </span>

              {error && <span className="error text-center mb-3">{error}</span>}

              <MDBBtn size="lg" onClick={handleLogin}>
                Login
              </MDBBtn>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    </MDBContainer>

    // <div className="container">
    //     <img
    //         className="mb"
    //         src={require('../images/maxxLogo.jpg')} // Set the width and height as needed
    //         alt="Maxx"
    //     />
    //     <h1 className="image">Artificial Intelligence</h1>
    //     <input
    //         type="text"
    //         placeholder="Username"
    //         onChange={(e) => setUsername(e.target.value)}
    //         value={username}
    //         className="input"
    //     />
    //     <input
    //         type="password"
    //         placeholder="Password"
    //         onChange={(e) => setPassword(e.target.value)}
    //         value={password}
    //         className="input"
    //     />
    //     <button onClick={handleForget} className="forgot_button">
    //         Forgot Password?
    //     </button>
    //     <button className="loginBtn" onClick={handleLogin}>
    //         Login
    //     </button>
    //     <p className="errorText">{error}</p>
    // </div>
  );
}

export default LoginScreen;
